import React from "react";
import "./styles.css";

const ctaWpp = (props) => {
  return (
    <figure id="ctaWpp">
      <a
        href="https://wa.me/5511990200707?text=Olá%20gostaria%20de%20conhecer%20os%20serviços%20de%20marketing%20digital"
        target="_blank"
        rel="noreferrer"
      >
        <img src={props.cta} alt="Chamada de ação para Whatsapp" />
      </a>
    </figure>
  );
};

export default ctaWpp;
